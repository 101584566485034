<script>
  // store modules
  import logsModule from "@/config/store/logs/inventory"
  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import logsCommonMixin from "@/pages/Logs/logsCommonMixin"

  // misc
  import { mapGetters } from "vuex"
  import { columns } from "./TableConfig"
  import { isSharedInventoryEnabled } from "@/helpers/organization"

  const storeUsageMixin = withStoreModule(logsModule, {
    resetState: true,
    name: "inventoryLogs",
    readers: {
      logs: "items",
      filters: "filters",
      logsLoading: "loading",
      pagination: "pagination"
    },
    actions: { fetchLogs: "FETCH_POST_ITEMS" },
    mutations: {
      applyFilters: "SET_FILTERS",
      setPagination: "SET_PAGINATION_DATA"
    }
  })

  export default {
    mixins: [logsCommonMixin, storeUsageMixin],

    computed: {
      ...mapGetters(["isOrixOrganization"]),

      initiators() {
        return this.extractInitiators({ add_auto_set_inventory: true, add_sale_stop_settings: true })
      },

      types({ isOrixOrganization }) {
        let types = [
          {
            id: "shop_inventory",
            name: this.$t("logs.inventory_log.types.shop_inventory")
          },
          {
            id: "shared_inventory",
            name: this.$t("logs.inventory_log.types.shared_inventory")
          },
          {
            id: "blocked_inventory",
            name: this.$t("logs.inventory_log.types.blocked_inventory")
          },
          {
            id: "available_inventory",
            name: this.$t("logs.inventory_log.types.available_inventory")
          },
          {
            id: "sale_stop",
            name: this.$t("logs.inventory_log.types.sale_stop")
          }
        ]

        const excludedIds = isSharedInventoryEnabled() ? ["shop_inventory"] : ["shared_inventory"]
        if (isOrixOrganization || isSharedInventoryEnabled()) excludedIds.push("blocked_inventory")

        return types.filter(({ id }) => !excludedIds.includes(id))
      }
    },

    data() {
      return {
        columns,
        tableWrapperClass: "inventory-logs"
      }
    }
  }
</script>
